<template>
  <div class="contactPage">
    <CollapsingImageBar title="Kapcsolat" image-url="/images/IMG_0767.webp" max-uncollapsed-height="400"/>

    <v-container class="my-5 mb-12">
      <v-row justify="center">
        <h2 class="font-weight-light">Lépj velünk kapcsolatba!</h2>
      </v-row>

      <v-row class="mt-5" justify="center">
        <v-col cols="12" md="7" lg="7" xl="4" class="hidden-sm-and-down">
          <v-img src="/images/271822263_4670223799759265_8100264471312102873_n.webp" class="mt-6 elevation-10 fill-height"/>
        </v-col>

        <v-col cols="12" md="5" lg="5">
          <v-row justify="center" class="mt-2 mx-1">
            <v-list>
              <h3 class="subtitle-1 text--secondary">Elérhetőségek:</h3>
              <v-list-item v-for="contact in contactData" :key="contact.text">
                <v-list-item-action>
                  <v-icon color="primary">{{ contact.icon }} </v-icon>
                </v-list-item-action>
                <v-list-item-content>{{ contact.text }}</v-list-item-content>
              </v-list-item>
              <h3 class="subtitle-1 text--secondary pt-4">Social media:</h3>

              <!-- Socials -->
              <v-list-item v-for="social in socials" :key="social.description" :href="social.link">
                <v-list-item-action>
                  <v-icon color="primary">{{ social.icon }} </v-icon>
                </v-list-item-action>
                <v-list-item-content>{{ social.description }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import CollapsingImageBar from "@/components/common/CollapsingImageBar";
import Footer from "@/components/common/Footer";

export default {
  name: "ContactPage",
  components: { Footer, CollapsingImageBar },
  data() {
    return {
      contactData: [
        { icon: "mdi-map-marker", text: "2072 Zsámbék, Petőfi Sándor u. 110.", },
        { icon: "mdi-phone-incoming", text: "06 (30) 966 0704" },
        { icon: "mdi-email", text: "tuzoltosagzsambek@gmail.com" },
        { icon: "mdi-piggy-bank", text: "Bankszámlaszám: 11736020-29908945-00000000 (OTP Bank Nyrt.)" },
        { icon: "mdi-bank", text: "Adószám: 18668621-1-13" },
      ],
      socials: [
        { icon: "mdi-facebook", link: "https://www.facebook.com/zsambekote", description: "Facebook oldalunk", },
        { icon: "mdi-youtube", link: "https://www.youtube.com/channel/UCcBq_m4o4M9_Z1D3Ye_8Kag", description: "Youtube csatornánk", },
        { icon: "mdi-instagram", link: "https://instagram.com/zsambekiifik?utm_medium=copy_link", description: "Instagramunk", },
      ],
    };
  },
};
</script>
