<template>
  <div class="museumPage">
    <CollapsingImageBar
      title="Múzeum"
      image-url="/images/muzeum_cover.webp"
      max-uncollapsed-height="400"
      subtitle="Látogasd meg múzeumunkat!"
    />
    <v-container class="my-6">
      <v-row justify="center">
        <v-col md="12" lg="12">
          <h2 class="primary--text mb-2">Helytörténeti kiállítás</h2>
          <p class="text-justify">
            Egyesületünk 2015-ben hozta létre a saját tűzoltó helytörténeti
            kiállítását a laktanya udvarán lévő kis fa épületben. A kiállított
            tárgyak részben az egyesület élete során összegyűjtött régi tűzoltó
            relikviák, részben pedig adományok, amiket az ide látogató vendégek,
            vagy tűzoltó barátok adtak a múzeum részére. A kiállítást telefonos
            egyeztetést követően INGYENESEN lehet megtekinteni.
          </p>
          Elérhetőség: +36309660704
          <p></p>
          <p>
            Szeretettel várjuk az érdeklődők látogatását! Óvodai és iskolai
            csoportokat is szívesen fogadjuk!
          </p>
        </v-col>
        <v-col md="12" lg="6">
          <v-img src="/images/1908033_1627394550857300_2035277528172254760_n.webp"></v-img>
        </v-col>
        <v-col md="12" lg="6">
          <v-img src="/images/11988667_1627394430857312_63943027062840173_n.webp"></v-img>
        </v-col>

      </v-row>

    </v-container>

    <Footer />
  </div>
</template>

<script>
import CollapsingImageBar from "@/components/common/CollapsingImageBar";
import Footer from "@/components/common/Footer";
export default {
  name: "MuseumPage",
  components: { Footer, CollapsingImageBar },
};
</script>

<style scoped></style>
