import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";


Vue.config.productionTip = false;

// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyA29Wo5uNPDKyUVEVLiVt3cdTMjP-0GFkg',
//     libraries: 'places'
//   }
// })

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
