<template>
  <div class="aboutPage">
    <CollapsingImageBar
      title="Rólunk"
      image-url="/images/tabla.webp"
      max-uncollapsed-height="400"
      subtitle="Kik vagyunk és mit csinálunk?"
    />

    <!--Egyesületünk-->
    <v-container>
      <h1 class="primary--text font-weight-medium mb-4">Bemutatkozás</h1>

      <v-row>
        <v-col cols="12" xl="6">
          <v-img src="/images/w1_IMG_9398.webp" class="elevation-10">
          </v-img>
        </v-col>
        <v-col cols="12" xl="6">
          <h3 class="mb-2">Néhány szó egyesületünkről</h3>
          <p class="text-justify">
            Zsámbék Város Önkéntes Tűzoltó Egyesülete a környék egyik legrégebb mai napig működő civil szervezete.
            Az első önkéntes tűzoltó egylet 1890-ben alakult meg, és utoljára 1995-ben szerveződött újra az egyesület,
            azóta működik és folyamatosan fejlődik. Szervezetünk közhasznú egyesületként látja el az alapszabályban rögzített
            feladatát, mely az önkéntes tűzoltás és műszaki mentés, katasztrófa elhárítás, valamint tűzvédelmi felvilágosító
            munkavégzés. Működési területünkhöz három település tartozik, Zsámbék Város, Tök Község és Perbál Község. 2017-től
            önálló beavatkozó tűzoltó egyesületként végezzük tevékenységünket.
          </p>
          <p class="text-justify">
            Egyesületünket a közgyűlések között egy öt fős vezetőség irányítja, a tagság pedig a vonulós tűzoltókon kívül áll még
            a nem vonulós tagokból, pártoló és tiszteletbeli tagokból. Ifjúsági tűzoltókat is foglalkoztatunk a jövőbeni utánpótlás
            biztosítása érdekében, itt a fiatalok az elméleti és gyakorlati alapokat tanulhatják meg a rendszeres foglalkozásokon.
            A szakmai tevékenységünk mellett fontos szerepet töltenek be a közösségi programok is, részt veszünk a települési ünnepségeken,
            tűzoltónapokon, gyakorlatokon, és mi magunk is szervezünk minden évben nyílt napot. Jó kapcsolatot ápolunk a helyi civil
            szervezetekkel és a környékbeli tűzoltó egyesületekkel. Külföldi kapcsolataink is vannak, több mint 30 éve ápolunk barátságot
            a németországi Wettenberg tűzoltóival, ahonnan rengeteg segítséget kaptunk ez idő alatt. Erdélyben a homoródszentmártoni
            tűzoltókkal van együttműködési megállapodásunk, Lengyelországban pedig Zawiercie önkéntes tűzoltói a barátaink.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <!--Gépjárműveink-->
    <v-container>
      <h1 class="primary--text font-weight-medium">Gépjárműveink</h1>
<!--      <p class="text&#45;&#45;secondary">Lorem ipsum dolor sit amet, consectetur adipisicing eagni officia perspiciatis quas repellendus veritatis.</p>-->
      <v-row >
        <v-col
          v-for="vehicle in vehicles"
          :key="vehicle.name"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <v-card height="100%" class="elevation-3">
            <v-img :src="vehicle.link" :lazy-src="vehicle.prev_link">
            </v-img>
            <v-card-title>{{ vehicle.name }}</v-card-title>
            <v-card-subtitle class="text--secondary font-weight-medium">Gyártási év: {{ vehicle.year }}</v-card-subtitle>
            <v-card-text class="text-justify">{{ vehicle.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

<!--Laktanyánk-->
    <v-container>
      <h1 class="primary--text font-weight-medium">Laktanyánk</h1>

      <v-row>
        <v-col cols="12" sm="6" md="5">
          <v-img src="/images/laktanya.webp" lazy-src="/images/laktanya_prev.webp" class="elevation-10 rounded-md">
          </v-img>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <h3 class=" mb-2">Ahol a tűzoltóautók alszanak</h3>
          <p class="text-justify">
            Szertárunk Zsámbék és Tök település határán található, az ellátandó terület szempontjából frekventált helyen. Az épületegyüttesben öt fűtött szerállás található, ezekből egynél elektromos garázskapu működik, három szerálláshoz pedig levegőrendszer csatlakozás lett kiépítve. A földszinti épületünkben egy kb. 50 fő befogadására alkalmas tanterem/klubhelyiség található egy konyhával és mosdóval. Az emeletes, egy szerállásos laktanyaépületet 2010-ben adták át az egyesület részére. Az emeleten található egy híradó helyiség, társalgó, irodahelyiségek, mosdók, konyha és egy háló. A laktanyaudvar mérete és adottságai lehetőséget teremtenek a szerelési gyakorlatokhoz és programok szervezéséhez is.
          </p>
        </v-col>
      </v-row>
    </v-container>

<!--Ifjúsági tűzoltók-->
    <v-container>
      <h1 class="primary--text font-weight-medium">Ifjúsági tűzoltók</h1>

      <v-row>
        <v-col cols="12" sm="6" md="7">
          <h3 class=" mb-2">Utánpótlás képzés</h3>
          <p class="text-justify">
            Egyesületünkben ifjúsági tűzoltók oktatásával is foglalkozunk. A jövőbeni utánpótlás biztosítása érdekében
            a fiatalok az elméleti és gyakorlati alapokat tanulhatják meg a rendszeres foglalkozásokon. Ezeken az oktatásokon
            ifjúsági tűzoltóink mélyebb betekintést nyerhetnek a tűzoltók munkájába, megismerhetik a használt eszközöket és elsajátíthatnak
            minden képességet, amire egy jövőbeni tűzoltónak szüksége lehet.
          </p>
          <p class="text-justify">
            Ha szeretnél többet megtudni a tűzoltók életéről, és te is próbára tennéd magad, jelentkezz ifjúsági tűzoltónak! Várunk csapatunkban, ha már elmúltál 10 éves.
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="5">
          <v-img src="/images/ifjusagi_tuzoltok.webp" lazy-src="/images/ifjusagi_tuzoltok_prev.webp" class="elevation-10">
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import CollapsingImageBar from "@/components/common/CollapsingImageBar";
import Footer from "@/components/common/Footer";
export default {
  name: "AboutPage",
  components: { Footer, CollapsingImageBar },
  data() {
    return {
      vehicles: [
        {
          name: "Mercedes 1224 AF",
          link: "/images/vehicles/mercedes_1224.webp",
          prev_link: "/images/vehicles/mercedes_1224_prev.webp",
          year: "1993",
          description:
            "A jelenlegi elsődleges beavatkozó szerünket 2022 év végén állami, önkormányzati, vállalkozói és civil támogatásokból vásároltuk Németországból, Buseck településről. A fecskendő 6 személy szállítására alkalmas, 2500 liter vízzel a felépítményén. Automata, normál nyomású szivattyúval, pneumatikus fényárbóccal és vízágyúval van felszerelve, valamint a legénységi fülkében két légzőkészülék is található.",
        },
        {
          name: "Iveco-Magirus 120-19 AW",
          link: "/images/vehicles/IMG_6247.webp",
          prev_link: "/images/vehicles/IMG_6247_prev.webp",
          year: "1985",
          description:
            "Tartalék beavatkozó szerként az Iveco-Magirus fecskendőt használjuk. 2016 nyarán érkezett hozzánk németországi testvértelepülésünkből Wettenbergből. A felépítményen 1600 liter víz, egy gyorsbeavatkozó és egy automata szivattyú található, valamint természetesen minden tűzoltáshoz és műszaki mentéshez szükséges szakfelszerelés. A fülke 9 személyes, és két beépített légzőkészülékkel van felszerelve.",
        },
        {
          name: "Volkswagen LT 40",
          link: "/images/vehicles/IMG_0940.webp",
          prev_link: "/images/vehicles/IMG_0940_prev.webp",
          year: "1991",
          description:
            "2019-ben érkezett Zsámbékra Wettenbergből ez a VW LT. Az autó ugyan kisebb a megszokottnál, de pont ebben rejlik az előnye! Vannak a működési területünkön olyan szűk, vagy éppen ágakkal benőtt utak, a Nyakas-hegy felé, ahol a nagy 12 tonnás teherautó nem fér el. Ilyen esetekben ez a 4,5 tonnás, négykerék-meghajtású jármű van segítségünkre. A fülke 7 személyes, a felépítményén pedig 500 liter víz, szivattyú, és minden olyan felszerelés megtalálható, ami tűzoltáshoz és műszaki mentéshez szükséges.",
        },
        {
          name: "Mercedes 1419 F DLK 37",
          link: "/images/vehicles/IMG_9547.webp",
          prev_link: "/images/vehicles/IMG_9547_prev.webp",
          year: "1979",
          description:
            "A magasból mentő szerünk a Stuttgart melletti Schwieberdingen településen lévő Bosch gyár létesítményi tűzoltóságán szolgált. Zsámbékra Karácsonyi Antal, helyi lakos jóvoltából került 2006-ban. A hidraulikus létraszerkezettel maximálisan elérhető magasság 37 méter. Egy esetleges magasból mentésnél, vagy épülettűznél való használat mellett kiváló eszköz a favágásokhoz, bérmunkákhoz.",
        },
        {
          name: "Fiat Ducato II. Maxi",
          link: "/images/vehicles/IMG_8963.webp",
          prev_link: "/images/vehicles/IMG_8963_prev.webp",
          year: "2001",
          description:
            "Csapatszállító járművünk szintén a németországi testvértelepülésről származik. 2021-ben hoztuk Magyarországra saját kerekein. Egy 8 személyes kisbusz, mely egyben egyszerűbb káreseteknél beavatkozásra is alkalmas, hiszen alapvető mechanikus szerszámokkal is fel van szerelve.",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
