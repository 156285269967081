<template>
  <nav>
    <v-app-bar app color="white" light>
      <v-app-bar-nav-icon
        color="primary"
        class="hidden-lg-and-up"
        @click="toggleAppDrawer"
      ></v-app-bar-nav-icon>
      <!--   logo   -->
      <router-link to="/">
        <v-img
          class="mx-1 hidden-sm-and-down"
          src="@/assets/logo_no_no_background_small.png"
          max-height="50"
          max-width="70"
          contain
        ></v-img>
        <v-img
          class="hidden-md-and-up"
          src="@/assets/logo_no_no_background_small.png"
          max-height="40"
          max-width="70"
          contain
        ></v-img>
      </router-link>

      <v-app-bar-title class="hidden-sm-and-down">
        <span>Zsámbék Város Önkéntes Tűzoltó Egyesület</span>
      </v-app-bar-title>
      <v-app-bar-title class="hidden-md-and-up">
        <span>{{ currentRouteName }}</span>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        elevation="0"
        class="hidden-md-and-down"
        text
        v-for="link in links"
        :key="link.text"
        router
        :to="link.route"
        color="primary"
      >
        {{ link.text }}
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawerOpen" disable-resize-watcher>
      <v-layout column align-center>
        <v-flex>
          <v-img
            class="my-5"
            src="@/assets/logo_no_no_background_small.png"
            max-height="150"
            max-width="150"
            contain
          ></v-img>
        </v-flex>
        <v-list nav>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
            color="primary"
          >
            <v-list-item-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      drawerOpen: false,
      links: [
        { icon: "mdi-home", text: "Főoldal", route: "/" },
        { icon: "mdi-account-group", text: "Rólunk", route: "/about" },
        { icon: "mdi-warehouse", text: "Múzeum", route: "/museum" },
        { icon: "mdi-hand-heart", text: "Közérdekű", route: "/publicInfo" },
        { icon: "mdi-image", text: "Galéria", route: "/gallery" },
        { icon: "mdi-phone", text: "Kapcsolat", route: "/contact" },
      ],
    };
  },
  methods: {
    toggleAppDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
  },
};
</script>
