<template>
  <div class="donorPage">
    <CollapsingImageBar
      title="Közérdekű"
      image-url="/images/IMG_2914.webp"
      max-uncollapsed-height="400"
    />

    <v-container>
      <h1 class="primary--text mt-8 mb-2">Dokumentumok</h1>

      <v-row>
        <v-col cols="12" lg="6" v-for="pdf in doc_pdfs" :key="pdf.title">
          <v-card>
            <v-list-item dense>
              <v-list-item-content>
                <v-card-title class="text-wrap text-break">
                  {{ pdf.title }}
                </v-card-title>
              </v-list-item-content>
              <v-list-item-action class="hidden-sm-and-down">
                <v-btn class="primary pr-4" :href="pdf.link" target="_blank">
                  <v-icon left>mdi-download</v-icon>
                  Pdf letöltése
                </v-btn>
              </v-list-item-action>
              <v-list-item-action class="hidden-md-and-up">
                <v-btn fab small color="primary" :href="pdf.link" target="_blank">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <h1 class="primary--text mt-2">Támogatóink</h1>

      <!--  top donors-->
      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          v-for="donor in top_donors"
          :key="donor.name"
        >
          <v-card>
            <v-img :src="donor.img_link" class="ma-2" contain height="350">
            </v-img>
            <v-card-title>{{ donor.name }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <!--  all donors-->
      <v-row class="mt-10">
        <v-col cols="12" md="6" lg="4" v-for="donor in all_donors" :key="donor">
          <v-card class="small">
            <v-card-title>{{ donor }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Footer />
  </div>
</template>

<script>
import CollapsingImageBar from "@/components/common/CollapsingImageBar";
import Footer from "@/components/common/Footer";
export default {
  name: "DonorsPage",
  components: { Footer, CollapsingImageBar },
  data() {
    return {
      top_donors: [],
      all_donors: [],
      public_pdfs: [],
      doc_pdfs: [],
    };
  },
  created: function () {
    fetch("/donors.json")
      .then((response) => response.json())
      .then((data) => {
        this.top_donors = data["top"];
        this.all_donors = data["all"];
      });
    fetch("/public_infos.json")
      .then((response) => response.json())
      .then((data) => {
        this.public_pdfs = data;
      });
    fetch("/public_docs.json")
      .then((response) => response.json())
      .then((data) => {
        this.doc_pdfs = data;
      });
  },
};
</script>

<style scoped></style>
