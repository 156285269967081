<template>
  <div class="mediaPage">
    <CollapsingImageBar
      title="Galéria"
      subtitle="Képek, videók"
      image-url="/images/139051992_1119561068507132_6352253223001213122_n.webp"
      max-uncollapsed-height="400"
    />
    <v-container class="my-5" v-for="album in albums" :key="album.title">
      <h1>{{ album.title }}</h1>
      <p class="text--secondary">{{ album.description }}</p>
      <!--   generate a unique masonry_class for this item   -->
      <v-row :class="'masonry_'.concat(album.id)">
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="img in album.images"
          :key="img.link"
          class="grid-item"
        >
          <router-link :to="img.link" target="_blank">
            <v-hover v-slot="{ hover }">
              <v-img
                :src="img.link"
                @load="onImgLaded"
                :class="hover ? 'elevation-12' : 'elevation-4'"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-hover>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Masonry from "masonry-layout";
import CollapsingImageBar from "@/components/common/CollapsingImageBar";
import Footer from "@/components/common/Footer";

export default {
  name: "Gallery",
  components: { Footer, CollapsingImageBar },
  data() {
    return {
      albums: [],
    };
  },
  methods: {
    onImgLaded() {
      for (let album of this.albums) {
        let className = ".masonry_".concat(album.id);
        new Masonry(className, {
          itemSelector: "[class*='col-']",
        });
      }
    },
  },

  created: function () {
    fetch("/gallery_images.json")
      .then((response) => response.json())
      .then((data) => {
        this.albums = data;
      });
  },
};
</script>
