<template>
  <div class="collapsingImageBar">
    <v-parallax
      :src="imageUrl"
      :height="maxUncollapsedHeight"
      class="hidden-sm-and-down"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <h1 class="font-weight-regular mt-10">{{ title }}</h1>
          <h4 v-if="subtitle !== ''" class="mt-5 subTitle font-italic pa-2">
            <span class="elevation-3">{{ subtitle }}</span>
          </h4>
        </v-col>
      </v-row>
    </v-parallax>
    <v-img
      class="hidden-md-and-up"
      :src="imageUrl"
      dark
      :max-height="maxUncollapsedHeight"
    >
      <v-row align="center" justify="center" class="fill-height">
        <v-col class="text-center">
          <h1 class="font-weight-regular mt-5 mx-2">{{ title }}</h1>
          <h4 v-if="subtitle !== ''" class="mt-5 subTitle font-italic pa-2">
            <span>{{ subtitle }}</span>
          </h4>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "CollapsingImageBar",
  props: {
    title: String,
    subtitle: {
      type: String,
      default: "",
    },
    imageUrl: String,
    maxUncollapsedHeight: {
      type: [String, Number],
      default: "300",
    },
  },
};
</script>

<style scoped>
.collapsingImageBar {
  font-size: calc(12px + 1.2vw);
  text-shadow: 0 0 15px #000000ff;
}
.subTitle span {
  padding: 6px 12px;
  background-color: white;
  text-shadow: none;
  color: #282828;
  border-radius: 4px;
}
</style>
