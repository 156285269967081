import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ContactPage from "@/views/ContactPage";
import Gallery from "@/views/GalleryPage";
import AboutPage from "@/views/AboutPage";
import DonorsPage from "@/views/DonorsPage";
import MuseumPage from "@/views/MuseumPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Főoldal",
    component: Home,
    meta: {
      title: "Főoldal - Zsámbéki tűzoltóság",
      metaTags: [
        {
          name: "description",
          content: "Zsámbék Város Önkéntes Tűzoltó Egyesület honlapja.",
        },
        {
          name: "og:description",
          content: "Zsámbék Város Önkéntes Tűzoltó Egyesület honlapja.",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "Rólunk",
    component: AboutPage,
    meta: {
      title: "Rólunk - Zsámbéki tűzoltóság",
      metaTags: [
        {
          name: "description",
          content: "Néhány szó az egyesületről és a tűzoltóságról",
        },
        {
          name: "og:description",
          content: "Néhány szó az egyesületről és a tűzoltóságról",
        },
      ],
    },
  },
  {
    path: "/publicInfo",
    name: "Közérdekű",
    component: DonorsPage,
    meta: {
      title: "Közérdekű információk - Zsámbéki tűzoltóság",
      metaTags: [
        {
          name: "description",
          content: "Egyesületünk támogatói, és lakossági tájékoztatók.",
        },
        {
          name: "og:description",
          content: "Egyesületünk támogatói, és lakossági tájékoztatók.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Kapcsolat",
    component: ContactPage,
    meta: {
      title: "Kapcsolat - Zsámbéki tűzoltóság",
      metaTags: [
        {
          name: "description",
          content: "Elérhetőségeink és közösségi média felületeink",
        },
        {
          name: "og:description",
          content: "Elérhetőségeink és közösségi média felületeink",
        },
      ],
    },
  },
  {
    path: "/gallery",
    name: "Galléria",
    component: Gallery,
    meta: {
      title: "Galéria - Zsámbéki tűzoltóság",
      metaTags: [
        {
          name: "description",
          content: "Rólunk készült képek",
        },
        {
          name: "og:description",
          content: "Rólunk készült képek",
        },
      ],
    },
  },
  {
    path: "/museum",
    name: "Múzeum",
    component: MuseumPage,
    meta: {
      title: "Múzeum - Zsámbéki tűzoltóság",
      metaTags: [
        {
          name: "description",
          content: "Információ az ingyenes helytörténeti múzeumunkról",
        },
        {
          name: "og:description",
          content: "Információ az ingyenes helytörténeti múzeumunkról",
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// const DEFAULT_TITLE = "Zsámbéki tűzoltóság hivatalos honlapja";
// eslint-disable-next-line no-unused-vars
// router.afterEach((to, from) => {
//   // Use next tick to handle router history correctly
//   // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//   Vue.nextTick(() => {
//     document.title = to.meta.title || DEFAULT_TITLE;
//   });
// });

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
