<template>
  <div class="homePage">
    <CollapsingImageBar
      title="Zsámbék Város Önkéntes Tűzoltó Egyesület"
      image-url="/images/IMG_0696.webp"
      max-uncollapsed-height="600"
      subtitle="Közjóért, becsületből!"
    />

    <div class="toborzas">
      <v-container class="my-10">
        <v-layout column align-center>
          <h2 class="title_red">Tagtoborzás</h2>
          <p class="body-2 text--secondary text-justify">
            Érdekel a tűzoltók világa? Szeretnéd próbára tenni a képességeidet?
            Várunk a csapatba!
          </p>
          <v-img src="/images/tagtoborzas.webp" max-height="450" contain>
          </v-img>
          <v-btn color="primary" class="mt-6" to="/contact"> Kapcsolat </v-btn>
        </v-layout>
      </v-container>
    </div>

    <div class="tamogatas">
      <v-container>
        <h2 class="text-center title_red mt-5">Támogasd a tűzoltók munkáját!</h2>
        <v-row class="my-2 mb-5 justify-center">
          <v-col cols="12" md="6" lg="6" xl="4">
            <v-img
              src="/images/IMG_1416.webp"
              contain
              max-height="400"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <p class="text-justify mx-sm-2 mx-md-0">
              Te tudod, hogy mi lehet a közös egy szobafestő, egy vagyonőr, egy tanuló, egy kertész és egy szakácsban? Például, hogy mindannyian önkéntes tűzoltók! A zsámbéki tűzoltók csapata is önkéntesekből áll, akik munkájuk és mindennapi teendőik mellett vállalják ezt a feladatot, fizetség nélkül. Ők készek az éjszaka közepén is felkelni, vagy az ebéd mellől felállni, ha ti segítségre szorultok. Legyen szó tűzről, viharkárról vagy balesetről, ránk számíthattok!
            </p>
            <p class="font-weight-bold mx-sm-2 mx-md-0">
              Mi is számítunk az segítségedre! Támogasd a tűzoltók munkáját adód 1%-ával!
            </p>
            <p class="font-weight-bold mx-sm-2 mx-md-0">
              Adószámunk: 18668621-1-13
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>


    <div class="munka">
      <v-img
        src="/images/37689769_1730242387090769_7158464572548972544_n.webp"
        max-height="400"
        gradient="to top, rgba(220,220,220,.55), rgba(220,220,220,.8)"
      >
        <v-layout column align-center justify-center class="fill-height">
            <h2 class="title_red text-center mt-2">Ismerd meg a munkánkat!</h2>
            <p>Káresetek, események, ünnepségek, egyesületi élet.</p>
            <v-btn color="primary" elevation="0" class="mt-10 mb-sm-4 mb-md-8" to="/gallery">Galéria</v-btn>
            <v-btn color="dark-grey" elevation="0" dark class="mt-2 mb-8" to="/about">Rólunk</v-btn>
        </v-layout>
      </v-img>
    </div>

    <Footer />
  </div>
</template>

<script>
import CollapsingImageBar from "@/components/common/CollapsingImageBar";
import Footer from "@/components/common/Footer";

export default {
  name: "Home",

  components: {
    Footer,
    CollapsingImageBar,
  },
};
</script>

<style scoped>
.tamogatas {
  background: #282828;
  color: white;
}


.title_red {
  color: #ce5252;
  font-size: 32px;
}
</style>
