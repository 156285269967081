<template>
  <v-footer light>
    <v-layout column>
      <v-row class="my-1" justify="center" align="center">
        <v-btn
          v-for="social in socials"
          :key="social.icon"
          :href="social.link"
          target="_blank"
          class="mx-1"
          elevation="0"
          plain
        >
          <v-icon size="24px" left>
            {{ social.icon }}
          </v-icon>
          {{ social.description }}
        </v-btn>
      </v-row>

      <v-divider />

      <v-row class="mt-4" justify="center" align="center">
        <v-btn elevation="0" small class="pr-10 black--text" height="40" href="https://bluecacti.dev/">
          <v-img
            src="/images/bcd_logo.svg"
            height="60"
            width="60"
            contain
            alt="Blue cacti dev"
          ></v-img>
          Blue Cacti Development
        </v-btn>
      </v-row>
      <v-row class="mb-4" justify="center" align="center">
        <p class="text--secondary caption">
          Készíttesd el a saját egyedi appod és weboldalad!
        </p>
      </v-row>

      <p class="body-2 ma-0 mt-2 text--secondary text-center font-weight-bold">
        Zsámbék Város Önkéntes Tűzoltó Egyesület
      </p>

      <p class="body-2 ma-0 mt-2 text--secondary text-center">
        2072 Zsámbék, Petőfi Sándor u. 110.
      </p>
      <p class="body-2 ma-0 text--secondary text-center">
        Adószám: 18668621-1-13
      </p>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      socials: [
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/zsambekote",
          description: "Facebook oldalunk",
        },
        {
          icon: "mdi-youtube",
          link: "https://www.youtube.com/channel/UCcBq_m4o4M9_Z1D3Ye_8Kag",
          description: "Youtube csatornánk",
        },
        {
          icon: "mdi-instagram",
          link: "https://instagram.com/zsambekiifik?utm_medium=copy_link",
          description: "Instagramunk",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
